<template>
  <Layout :tituloPagina="`Hotspot | Vendedores | ${modo=='nuevo'?'Nuevo': 'Edición de'} vendedor`">
    <div class="row">
      <div class="col-xxl-3">
        <div class="user-sidebar">
          <div class="card">
            <div class="card-body p-0">
              <div class="user-profile-img">
                <img
                  src="@/assets/images/pattern-bg.jpg"
                  class="profile-img profile-foreground-img rounded-top"
                  style="height: 120px"
                />
                <div class="overlay-content rounded-top">
                  <div>
                    <div class="user-nav p-3">
                      <div class="d-flex justify-content-end">
                        <div class="dropdown">
                          <a
                            class="dropdown-toggle"
                            href="javascript: void(0);"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <eva-icon
                              name="more-horizontal-outline"
                              data-eva-width="20"
                              data-eva-height="20"
                              class="fill-white"
                            ></eva-icon>
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" @click="seleccionarFoto()">
                                <eva-icon name="repeat" class="text-primary"></eva-icon>
                                {{foto == null ? 'Seleccionar ' : 'Cambiar '}} imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="eliminarFoto()">
                                <eva-icon name="trash-2-outline" class="text-danger"></eva-icon>
                                Eliminar imagen
                              </button>
                            </li>
                            <li>
                              <button class="dropdown-item" @click="visibleImagen = true">
                                <eva-icon name="eye-outline" class="text-info"></eva-icon>
                                Ver imagen completa
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-n5 position-relative">
                <div class="text-center">
                  <div v-show="foto == null" class="auth-logo">
                    <img
                      :src="vendedoricon"
                      alt="Foto del vendedor"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-dark"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                    <img
                      :src="vendedoriconblanco"
                      alt="Foto del vendedor"
                      class="avatar-xl rounded-circle img-thumbnail cursor auth-logo-light"
                      title="Seleccionar imagen"
                      @click="seleccionarFoto()"
                    />
                  </div>
                  <div v-if="foto != null">
                    <img 
                      :src="foto" 
                      class="avatar-xl rounded-circle img-thumbnail cursor" 
                      alt="Foto del vendedor" 
                      title="Ver imagen completa"
                      @click="visibleImagen = true"
                    />
                    <vue-easy-lightbox
                      :visible="visibleImagen"
                      :imgs="foto_visor"
                      @hide="visibleImagen = false"
                    ></vue-easy-lightbox>
                  </div>

                  <div class="mt-3">
                    <h5 class="mb-1">
                      {{ vendedor.nombre != '' ? vendedor.nombre : 'Nombre(s)' }} 
                      {{ vendedor.apellido_paterno != '' ? vendedor.apellido_paterno : 'Apellido' }} 
                      {{ vendedor.apellido_materno != '' ? vendedor.apellido_materno : 'Apellido' }}
                    </h5>
                  </div>
                  <div class="mt-4"></div>
                </div>
              </div>
              <div class="p-3 mt-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xxl-9">
        <div class="card">
          <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#sistema"
                role="tab"
              >
                <i class="bx bx-cog font-size-20"></i>
                <span class="d-none d-sm-block">Sistema</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#datos"
                role="tab"
                v-if="modo != 'nuevo'"
              >
                <i class="bx bx-user-circle font-size-20"></i>
                <span class="d-none d-sm-block">Datos personales</span>
              </a>
            </li>
            <li class="nav-item"></li>
            <li class="nav-item"></li>
          </ul>

          <div class="tab-content p-4" style="min-height: 300px;">
            <div class="tab-pane active" id="sistema" role="tabpanel">
              <h5>Claves de acceso</h5>

              <div class="row pt-0">
                <div class="col-md-3" v-if="modo=='edicion'">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="idVendedor"
                      placeholder="#ID"
                      readonly
                      required
                      v-model="vendedor.id"
                    />
                    <label for="idvendedor">ID</label>
                    <div class="form-floating-icon">
                      <eva-icon name="hash-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="identidad"
                      ref="identidad"
                      placeholder="Identidad"
                      required
                      v-model="vendedor.identidad"
                    />
                    <label for="identidad">Identidad</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                    <input
                      :type="type"
                      class="form-control pe-5"
                      id="clave"
                      ref="clave"
                      placeholder="Clave"
                      required=""
                      v-model="vendedor.clave"
                    />
                    <button 
                      type="button"
                      class="btn btn-link position-absolute h-100 end-0 top-0"
                      id="clave-addon"
                      @click="type == 'text' ? type ='password': type ='text'"
                    >
                      <i class="mdi mdi-eye-outline font-size-18 text-muted"></i>
                    </button>
                    <label for="password-input">Clave</label>
                    <div class="form-floating-icon">
                      <eva-icon name="lock-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              
              <br v-if="modo == 'nuevo'">

              <div v-if="modo == 'nuevo'">
                <h5>Datos personales</h5>
              
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="nombre"
                        name="nombre"
                        ref="nombre"
                        placeholder="Nombre del vendedor"
                        required
                        v-model="vendedor.nombre"
                      />
                      <label for="nombre">Nombre del vendedor</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="apellido_paterno"
                        name="apellido_paterno"
                        placeholder="Apellido paterno del vendedor"
                        required
                        v-model="vendedor.apellido_paterno"
                      />
                      <label for="apellido_paterno">Apellido paterno</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="apellido_materno"
                        name="apellido_materno"
                        placeholder="Apellido paterno del vendedor"
                        required
                        v-model="vendedor.apellido_materno"
                      />
                      <label for="apellido_materno">Apellido materno</label>
                      <div class="form-floating-icon">
                        <eva-icon name="people-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <h5>Contacto de vendedor</h5>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="telefono"
                        name="telefono"
                        placeholder="telefono del vendedor"
                        required
                        v-model="vendedor.telefono"
                      />
                      <label for="telefono">Teléfono o celular</label>
                      <div class="form-floating-icon">
                        <eva-icon name="phone-call-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="correo"
                        name="correo"
                        placeholder="correo del vendedor"
                        required
                        v-model="vendedor.correo"
                      />
                      <label for="correo">Correo electrónico</label>
                      <div class="form-floating-icon">
                        <eva-icon name="at-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <!-- Comisiones -->
              <div v-show="modo=='nuevo'">
                <h5>Comisión</h5>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="comision_fichas"
                        name="comision_fichas"
                        placeholder="Comisión de fichas"
                        required
                        v-model="vendedor.comision_fichas"
                        @change="vendedor.porcentaje_comision_fichas=0"
                      />
                      <label for="comision_fichas">Comisión de fichas</label>
                      <div class="form-floating-icon">
                        <eva-icon name="credit-card-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="porcentaje_comision_fichas"
                        name="porcentaje_comision_fichas"
                        placeholder="Porcentaje de comisión por ficha"
                        required
                        v-model="vendedor.porcentaje_comision_fichas"
                        @change="vendedor.comision_fichas=0"
                      />
                      <label for="porcentaje_comision_fichas">Porcentaje de comisión por ficha</label>
                      <div class="form-floating-icon">
                        <eva-icon name="percent-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Comisiones -->

              <br>
              <div class="row" v-show="modo=='edicion'">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <h5>Roles de vendedor</h5>
                  <span v-for="rol in vendedor.roles" :key="rol.id"
                    class="badge bg-success p-2 m-1 font-size-12 bg-gradient fw-semibold rounded" style="display: inline-block;">
                    {{rol.nombre}}
                  </span>
                </div>
                <!-- Estado del vendedor -->
                <div class="col-lg-6 col-md-6 col-sm-6" v-show="modo=='edicion'">
                  <h5>Estado del vendedor</h5>
                  <div class="form-floating form-floating-custom mb-4">
                    <select
                      class="form-select"
                      id="activo"
                      v-model="vendedor.activo"
                    >
                      <option :value="1">Activo</option>
                      <option :value="0">Inactivo</option>
                    </select>
                    <label for="activo">Estado del vendedor</label>
                    <div class="form-floating-icon">
                      <eva-icon :name="vendedor.activo ? 'person-done-outline' : 'person-remove-outline'"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane" id="datos" role="tabpanel" v-if="modo != 'nuevo'">
              <h5>Datos personales</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="nombre"
                      name="nombre"
                      ref="nombre"
                      placeholder="Nombre del vendedor"
                      required
                      v-model="vendedor.nombre"
                    />
                    <label for="nombre">Nombre del vendedor</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="apellido_paterno"
                      name="apellido_paterno"
                      placeholder="Apellido paterno del vendedor"
                      required
                      v-model="vendedor.apellido_paterno"
                    />
                    <label for="apellido_paterno">Apellido paterno</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="apellido_materno"
                      name="apellido_materno"
                      placeholder="Apellido paterno del vendedor"
                      required
                      v-model="vendedor.apellido_materno"
                    />
                    <label for="apellido_materno">Apellido materno</label>
                    <div class="form-floating-icon">
                      <eva-icon name="people-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <h5>Contacto de vendedor</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="telefono"
                      name="telefono"
                      placeholder="telefono del vendedor"
                      required
                      v-model="vendedor.telefono"
                    />
                    <label for="telefono">Teléfono o celular</label>
                    <div class="form-floating-icon">
                      <eva-icon name="phone-call-outline"></eva-icon>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating form-floating-custom mb-4">
                    <input
                      type="text"
                      class="form-control"
                      id="correo"
                      name="correo"
                      placeholder="correo del vendedor"
                      required
                      v-model="vendedor.correo"
                    />
                    <label for="correo">Correo electrónico</label>
                    <div class="form-floating-icon">
                      <eva-icon name="at-outline"></eva-icon>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <!-- Comisiones -->
              <div>
                <h5>Comisión</h5>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="comision_fichas"
                        name="comision_fichas"
                        placeholder="Comisión de fichas"
                        required
                        v-model="vendedor.comision_fichas"
                        @change="vendedor.porcentaje_comision_fichas=0"
                      />
                      <label for="comision_fichas">Comisión de fichas</label>
                      <div class="form-floating-icon">
                        <eva-icon name="credit-card-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-floating form-floating-custom mb-4">
                      <input
                        type="text"
                        class="form-control"
                        id="porcentaje_comision_fichas"
                        name="porcentaje_comision_fichas"
                        placeholder="Porcentaje de comisión por ficha"
                        required
                        v-model="vendedor.porcentaje_comision_fichas"
                        @change="vendedor.comision_fichas=0"
                      />
                      <label for="porcentaje_comision_fichas">Porcentaje de comisión por ficha</label>
                      <div class="form-floating-icon">
                        <eva-icon name="percent-outline"></eva-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Comisiones -->
            </div>
          </div>
        </div>
        <div class="text-right pb-4">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="modo=='nuevo'?guardar():actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{modo=='nuevo'?'Guardar': 'Actualizar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import VendedorSrv from '@/services/hotspot/VendedorSrv.js'
import vendedoricon from '@/assets/img/logoArgus.png'
import vendedoriconblanco from '@/assets/img/logoArgusBlanco.png'
import Layout from "@/views/layouts/main"
import VueEasyLightbox from "vue-easy-lightbox"
import API from '@/API.js'
export default {
  name: 'EdicionVendedor',
  props: ['id'],
  components: { Layout, VueEasyLightbox }, 
  data() {
    return {
      modo: 'nuevo',
      tk: localStorage.getItem('argusblack.token'),
      rnd: Math.random(),
      API: API,
      baseUrl: window.location.origin,
      vendedoricon: vendedoricon,
      vendedoriconblanco: vendedoriconblanco,
      idFoto: null,
      vendedor: {
        id: null,
        identidad: '',
        clave: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefono: '',
        correo: '',
        id_foto: null,
        activo: 1,
        roles: [],
        foto: null,
        comision_fichas: 0,
        porcentaje_comision_fichas: 0
      },
      foto: null,
      foto_visor: null,
      bandera_spinner: false,
      visibleImagen: false,
      type:'password',
    }
  },
  created: function() {
    var self = this

    // Carga del modo
    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'

    // Carga Vendedor
    if (this.modo == 'edicion') self.cargarVendedor()
  },
  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      // Verificación de datos correctos
      if (!self.verificarDatosCorrectos()) return

      let vendedor = Object.assign({}, self.vendedor)
      delete vendedor.roles

      VendedorSrv.actualizar(vendedor).then(response => {
        iu.msg.success('Se actualizó correctamente')

        if (
          self.vendedor.id == self.$store.state.todo.usuario.id &&
          (self.vendedor.identidad != self.$store.state.todo.usuario.identidad ||
          self.vendedor.clave != self.$store.state.todo.usuario.clave)
        ) {
          iu.msg.warning('Es necesario volver a iniciar la sesión')
          localStorage.removeItem('argusblack.token')
          self.$router.push({ name: 'login' })
        } else {
          self.cargarVendedor()
        }
      }).catch(error => {
        let message = error.response.data.message || 'No se pudo actualizar'
        iu.msg.error(message)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    asignarRol(rol) {
      let res = this.vendedor.roles.find(r => r.id == rol.id)
      
      // Si está asignado el rol, se evita volver a asignarlo
      if(res != undefined) return;

      this.vendedor.roles.push(rol)
    },
    cargarVendedor() {
      var self = this

      VendedorSrv.vendedorJSON(this.id).then(response => {
        let vendedor = response.data
        self.rnd = Math.random()
        self.vendedor = vendedor

        if(self.vendedor.id_foto != null) {
          self.foto = self.API + '/usuarios/' + self.vendedor.id + '/foto?ancho=256&alto=256&_tk=' + self.tk + '&rnd=' + Math.random()
          self.foto_visor = self.API + '/usuarios/' + self.vendedor.id + '/foto?original=1&_tk=' + self.tk + '&rnd=' + Math.random()
        }
      }).catch(error => {
        let message = error.response.data.message || 'vendedor no encontrado'
        iu.msg.error(message)
        console.log(error)
      })
    },
    cerrar: function() {
      this.$router.go(-1)
    },
    eliminarFoto: function() {
      var self = this
      self.foto = null
      self.foto_visor = null
      self.vendedor.eliminar_foto_antigua = 1
    },
    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) {
        return
      }
      
      let vendedor = Object.assign({}, self.vendedor)
      delete vendedor.id
      delete vendedor.id_foto
      vendedor.roles = [4]
        
      VendedorSrv.guardar(vendedor).then(response => {
        iu.msg.success('Nuevo vendedor guardado')
        var id = response.data
        
        self.modo = 'edicion'
        self.vendedor.id = id
        self.vendedor.roles = [ {id:4, nombre: 'Vendedor de fichas'}]

        self.$router.replace({
          name: 'edicionVendedorHotspot',
          params: {
            id: id,
            nuevoVendedor: true
          }
        })
      }).catch(error => {
        let message = error.response.data.message || 'No se pudo guardar, intenta nuevamente'
        iu.msg.error(message)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    seleccionarFoto: function() {
      let self = this
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')
      var reader = new FileReader()

      inpFoto.click()
      
      reader.onload = function(e) {
        self.foto = this.result
        self.foto_visor = this.result
      }

      inpFoto.addEventListener('change', function(){
        self.vendedor.foto = inpFoto.files[0]
        self.vendedor.eliminar_foto_antigua = true
        reader.readAsDataURL(self.vendedor.foto)
      })
    },

    verificarDatosCorrectos: function() {
      var self = this

      if (self.vendedor.identidad == '') {
        iu.msg.warning('Es necesaria una identidad')
        self.$refs.identidad.select()
        self.bandera_spinner = false
        return false
      }

      if (self.vendedor.clave == '') {
        iu.msg.warning('Es necesaria una clave')
        self.$refs.clave.select()
        self.bandera_spinner = false
        return false
      }

      if (self.vendedor.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return false
      }
      
      return true
    }
  }
}
</script>

<style scoped>
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.cursor{
  cursor: pointer;
}
</style>